"use client";

import dynamic from "next/dynamic";
import Link from "next/link";
import { useIntl } from "react-intl";

export const HeroSection = () => {
  const intl = useIntl();
  const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });

  return (
    <div className="relative w-full overflow-hidden bg-white dark:bg-black">
      <div className="relative aspect-video">
        <ReactPlayer
          className="absolute left-1/2 top-1/2 max-h-full min-w-full	object-cover -translate-x-1/2 -translate-y-1/2"
          height="auto"
          loop
          muted
          playing
          url={[
            { src: "hero-video.mp4", type: "video/mp4" },
            { src: "hero-video.webm", type: "video/webm" },
          ]}
          width="100%"
        />
        <div className="absolute inset-0 bg-canvas-dark-2/60" />
      </div>
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="px-4 text-center md:px-8">
          <h1 className="mx-auto max-w-4xl text-2xl font-bold leading-tight tracking-tight text-neutral-contrast dark:text-neutral-12 sm:text-3xl md:text-6xl lg:text-7xl">
            {intl.formatMessage(
              {
                defaultMessage: "Welcome to the{lineBreak}Defense Futures Simulator",
                id: "nuZ7bm",
              },
              {
                lineBreak: <br />,
              },
            )}
          </h1>
          <p className="mx-auto mt-4 max-w-2xl text-sm font-normal tracking-tight text-neutral-contrast dark:text-neutral-12 sm:text-base md:text-lg">
            {intl.formatMessage({
              defaultMessage: "Create, simulate, and analyze alternative strategies and budgets.",
              id: "KIITmJ",
            })}
          </p>
          <div className="mt-6 sm:mt-8">
            <Link
              className="inline-block rounded-md bg-primary-dark-9 px-4 py-2 text-sm font-semibold text-primary-contrast shadow-md transition-colors duration-200 hover:bg-primary-dark-10 sm:text-base"
              href="/sign-up"
            >
              <span>
                {intl.formatMessage({
                  defaultMessage: "Get started",
                  id: "/aBLH2",
                })}
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
